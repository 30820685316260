<template>
    <div class="view moneyTable">
        <div class="oyauto">
            <table border="1" cellspacing="0" class="tableBorder assistrecordTable" :style="tableStyle">
                <thead>
                    <tr class="h40 baf7fafa">
                        <td class="textc itemTitle" colspan="6" width="930px">
                            凭证科目/研发项目费用
                        </td>
                        <td class="textc itemTitle" v-for="(row, index) in tableData.title" :key="index">
                            {{ row.code }}</td>
                    </tr>
                    <tr class="h70 baf7fafa">
                        <td class="textc itemTitle w100">
                            日期
                        </td>
                        <td class="textc itemTitle w100">
                            凭证号
                        </td>
                        <td class="textc itemTitle w150">
                            摘要
                        </td>
                        <td class="textc itemTitle w230">
                            科目
                        </td>
                        <td class="textc itemTitle w150">
                            凭证金额
                        </td>
                        <td class="textc itemTitle w200">
                            计入研发费用金额(元)
                        </td>
                        <td class="textc itemTitle" v-for="(row, index) in tableData.title" :key="index">
                            {{ row.name }}</td>
                    </tr>
                    <tr class="baf7fafa" v-if="tableData.list && tableData.list.length > 0">
                        <td class="w100"></td>
                        <td class="w100"></td>
                        <td class="w150"></td>
                        <td class="w230"></td>
                        <td class="w150"></td>
                        <td class="w200"></td>
                        <td class="itemTitle textc h40" v-for="(row, index) in tableData.title" :key="index">{{
                row.startDay + " 至 " + row.endDay }}</td>
                    </tr>
                </thead>
                <tbody v-if="tableData.list && tableData.list.length > 0" class="overflowHide"
                    :style="{ 'max-height': tableHeight + 'px' }">
                    <tr class="h40" v-for="(row, index) in tableData.list" :key="index">
                        <!-- @click="showModelFun(row)"> -->
                        <td class="textc itemContent w100">{{ row.day }}</td>
                        <td class="textc itemContent w100">{{ row.voucherCode }}</td>
                        <td class="textc itemContent w150 curPointer" @click="hrefFun">{{ row.remark }}</td>
                        <td class="textc itemContent w230">{{ row.subject }}</td>
                        <td class="textc itemContent w150 curPointer" @click="hrefFun">{{ row.voucherMoney | setMoney }}
                        </td>
                        <td class="textc itemContent w200 curPointer" @click="hrefFun">{{ row.researchMoney | setMoney
                            }}</td>
                        <td class="textc itemContent curPointer" v-for="(money, key) in row.data" :key="key">
                            <!-- <el-tooltip class="tooltipItem flex-c-c" effect="dark" :content="row.scaleData[key] + '%'"
                                :class="{ 'on': projectKey.indexOf(index + '-' + key) != -1 }" placement="top">
                                <div>{{ money | setMoney }}</div>
                            </el-tooltip> -->
                            <el-popover placement="top" widht="150" trigger="click">
                                <p>百分比：{{ row.scaleData[key].toFixed(4) + '%' }}</p>
                                <div class="flex-c-c mt10" v-if="row.isEasy || !('isEasy' in row)">
                                    <el-button size="mini" type="danger" @click="delDataFun(row)"
                                        v-if="row.updateScale">重置金额</el-button>
                                    <el-button type="primary" size="mini" @click="showModelFun(row)">编辑</el-button>
                                </div>
                                <div slot="reference">
                                    <el-tooltip class="tooltipItem flex-c-c" effect="dark"
                                        :content="row.scaleData[key] + '%'" placement="top">
                                        <div>{{ money | setMoney }}</div>
                                    </el-tooltip>
                                </div>

                            </el-popover>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr class="h200 textc curPointer" @click="hrefFun">
                        <td colspan="3">暂无数据</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr class="h40 fwbold baf7fafa" v-if="tableData.total">
                        <td class="textc itemContent" colspan="4" width="580px">合 计</td>
                        <td class="textc itemContent w150">{{ tableData.total.voucherMoney | setMoney }}</td>
                        <td class="textc itemContent w200">{{ tableData.total.researchMoney | setMoney }}</td>
                        <td class="textc itemContent" v-for="(money, key) in tableData.total.data" :key="key + 'total'">
                            {{
                money | setMoney }}</td>
                    </tr>
                    <tr class="h40 fwbold baffefd3" v-if="tableData.proportion">
                        <td class="textc itemContent" colspan="4" width="580px">其他费用占总费用比例</td>
                        <td class="textc itemContent w150">-</td>
                        <td class="textc itemContent w200">-</td>
                        <td class="textc itemContent" v-for="(money, key) in tableData.proportion.data"
                            :key="key + 'total'">
                            {{
                money | setMoney }}%</td>
                    </tr>
                </tfoot>
            </table>
        </div>
        <el-dialog title="编辑项目预算" :close-on-click-modal="false" :modal-append-to-body="false" :visible.sync="showModel"
            width="80%" @close="closeDialog">
            <div>
                <div v-if="setData.row">
                    <div class="mb10">日期：{{ setData.row.day }}</div>
                    <div class="mb10">凭证号：{{ setData.row.voucherCode }}</div>
                    <div class="mb10">摘要：{{ setData.row.remark }}</div>
                    <div class="mb10">科目：{{ setData.row.subject }}</div>
                </div>
                <div class="line mt20 mb20"></div>
                <div>
                    <span class="mr20">项目预算比例</span>
                    <el-input-number class="w150" :disabled="(projectIndex !== 0 && !projectIndex) || totalNumber === 0"
                        size="mini" v-model="n" @change="changeSlider" :min="0" :max="100"></el-input-number>
                </div>
                <div class="flex-c-c mb40 pl10 pr10">
                    <el-slider v-model="n" :disabled="(projectIndex !== 0 && !projectIndex) || totalNumber === 0"
                        :marks="marks" style="width:100%" @input="changeSlider"></el-slider>
                </div>
                <div class="flex-a-c flex-wrap-y">
                    <div class="ml10 mr10 fwbold"
                        :class="{ 'errorShow': (difference / totalNumber) > 1 || (difference / totalNumber) < 1 }">
                        <div class="mb10 mt10">研发费总额 {{ totalNumber }}</div>
                        <div class="">
                            <div>已分配总额</div>
                            <el-input v-model="difference" type="number" class="w200 errTips" readonly>
                                <div slot="suffix" class="el-input__icon">{{ setData.proportion }}%</div>
                            </el-input>
                        </div>
                        <div class="mb10 mt10 errTips">研发费总额-已分配金额={{ difference - totalNumber != 0 ?
                Math.abs(difference -
                    totalNumber).toFixed(4) : 0 }}
                        </div>
                    </div>
                    <div v-for="(row, index) in projectData" :key="index" class="ml10 mr10">
                        <div class="mb10 mt10">编号：{{ row.code }}</div>
                        <div class="mb10 mt10">
                            <el-checkbox v-model="row.checkedProject" :disabled="!row.isUpdate"
                                @change="checkedProjectChange($event, index)">{{ row.name }}</el-checkbox>
                        </div>
                        <el-input v-model="row.dataNumber" :disabled="!row.isUpdate" type="number" :max="totalNumber"
                            class="w200" @focus="focusInput(index)" @change="changeNumber">
                            <div slot="suffix" class="el-input__icon">{{ row.proportion }}%</div>
                        </el-input>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="submitData">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "moneyTable",
    props: {
        tableData: {
            require: true,
            type: Object,
            default: () => {
                return {
                    title: [{ name: "项目名称", code: "项目编号", endDay: "-", startDay: "-" }]
                }
            }
        },
    },
    data() {
        return {
            modelTitle: "",
            tableHeight: 0,
            showModel: false,
            n: 0,
            difference: 0,
            totalNumber: 0,
            setData: {},
            marks: {
                0: '0%',
                100: '100%'
            },
            projectIndex: null,
            projectData: [],
            tableStyle: {},
        };
    },
    filters: {
        setMoney(money) {
            return (money ? money : 0).toFixed(2)
        }
    },
    watch: {
        tableData: {
            handler() {
               //-console.log("talbe");
                if (this.tableData.title) {
                    let tableW = 1000 + (this.tableData.title.length * 200);
                    if (tableW > $(".view").width()) {
                        this.tableStyle = {
                            width: tableW + 'px'
                        }
                    } else {
                        this.tableStyle = {
                            width: 'auto'
                        };
                    }

                } else {
                    this.tableStyle = {
                        width: 'auto'
                    };
                }
            },
            immediate:true,
            deep:true,
        }
    },
    mounted() {
        this.tableHeight = $(".content").height() - 75 - (25 + 56 + 56 + 60 + 74) - (40 + 70 + 40);
    },
    methods: {
        /**@method  选中编辑金额*/
        checkedProjectChange(isChecked, index) {
            let difference = 0;
            if (isChecked) {
                let updateData = [];
                let dataNumber = this.totalNumber * (this.projectData[index].oldProportion / 100);
                this.projectData[index].dataNumber = (dataNumber).toFixed(2);
                this.projectData[index].proportion = (this.projectData[index].oldProportion).toFixed(4);
                for (let row of this.projectData) {
                    if (row.checkedProject && row.isUpdate) {
                        updateData.push(row);
                        difference += Number(row.dataNumber);
                    }
                }
            } else {
                let projectDataLength = 0;//分摊数量
                this.projectData[index].oldProportion = Number(this.projectData[index].proportion);
                for (let row of this.projectData) {
                    if (row.checkedProject && row.isUpdate) {
                        projectDataLength++;
                    }
                }
                let averageValue = this.projectData[index].dataNumber / projectDataLength;//平均每份的值
                averageValue = Number(averageValue.toFixed(2));
                let isUpdateIndex = 0;
                for (let i in this.projectData) {
                    let row = this.projectData[i];
                    if (row.checkedProject && row.isUpdate) {
                        isUpdateIndex = i;
                        row.dataNumber = (Number(row.dataNumber) + averageValue).toFixed(2);
                        row.proportion = ((this.totalNumber && row.dataNumber ? Number(row.dataNumber) / this.totalNumber : 0) * 100).toFixed(4);
                        difference += Number(row.dataNumber);
                    }
                }
                let errNumber = this.totalNumber - difference;//误差
                difference += errNumber;
                this.projectData[isUpdateIndex].dataNumber = (Number(this.projectData[isUpdateIndex].dataNumber) + errNumber).toFixed(2);
                this.projectData[isUpdateIndex].proportion = ((this.totalNumber && this.projectData[isUpdateIndex].dataNumber ? Number(this.projectData[isUpdateIndex].dataNumber) / this.totalNumber : 0) * 100).toFixed(4);
                this.projectData[index].dataNumber = 0;
                this.projectData[index].proportion = 0;


            }
            this.difference = Number(difference.toFixed(2));
            this.setData.proportion = ((this.difference && this.totalNumber ? this.difference / this.totalNumber : 0) * 100).toFixed(4);
        },
        /**@method 删除条 */
        delDataFun(row) {
            this.$emit("delDataFun", row)
        },
        closeDialog() {
            this.showModel = false;
            this.n = 0;
            this.projectIndex = null;
        },
        /** */
        changeNumber(val) {
            this.n = Number(((Number(val) / this.totalNumber) * 100).toFixed(2));
        },
        /**@method 获取光标操作 */
        focusInput(i) {
            this.projectIndex = i;
            this.n = Number(this.projectData[i].proportion);
            // this.projectData[i].proportion = ((this.projectData[i].dataNumber / this.totalNumber) * 100).toFixed(4)
        },
        /**@method 调节滚动条 */
        changeSlider(val) {
            if (this.projectIndex || this.projectIndex === 0) {
                let difference = 0;
                let dataNumber = (this.totalNumber * (val / 100)).toFixed(2);
                let residueNumber = this.totalNumber - dataNumber;
                this.$set(this.projectData[this.projectIndex], "dataNumber", dataNumber);
                if (residueNumber >= 0) {
                    for (let row of this.projectData) {
                        difference += Number(row.dataNumber);
                        row.proportion = ((row.dataNumber && this.totalNumber ? row.dataNumber / this.totalNumber : 0) * 100).toFixed(4)
                    }
                }
                this.difference = Number(difference.toFixed(2))
                this.setData.proportion = ((this.difference && this.totalNumber ? this.difference / this.totalNumber : 0) * 100).toFixed(4);
            }
        },
        /**@method 显示模态框 */
        showModelFun(data) {
           //-console.log(data);
            let moneys = 0;
            let researchMoney = data.researchMoney ? data.researchMoney : 0;
            let project = [];
            this.difference = researchMoney;
            this.totalNumber = researchMoney;
            for (let index in data.data) {
                let row = this.tableData.title[index];
                moneys += row.money;
                row.isUpdate = data.haveData[index];
                row.dataNumber = data.data[index] ? data.data[index] : 0;
                row.checkedProject = data.haveData[index];

                row.proportion = ((data.data[index] && researchMoney ? data.data[index] / researchMoney : 0) * 100).toFixed(4);
                row.oldProportion = Number((data.data[index] && researchMoney ? data.data[index] / researchMoney : 0) * 100);
                let tiem = {
                    ...row,
                    ...data
                }
                project.push(tiem);
            }

            this.setData = {
                row: data,
                moneys: moneys ? moneys : 0,
                proportion: ((this.difference && researchMoney ? this.difference / researchMoney : 0) * 100).toFixed(4)
            };
            this.projectData = JSON.parse(JSON.stringify(project));
            this.showModel = true;
        },
        /**@method 提交修改值 */
        submitData() {
            if (Number(this.difference) > Number(this.totalNumber)) {
                this.$message.warning("项目预算比例总和不能超过100%");
            } else if (Number(this.difference) < Number(this.totalNumber)) {
                this.$message.warning("项目预算比例总和必须100%");
            } else {
                this.$emit("updateDataFun", { row: this.setData.row, data: this.projectData });
            }
        },
        hrefFun() {
            this.$emit("nullListHref")
        },
    }
};
</script>

<style lang="scss">
.errorShow {
    .errTips {
        color: red !important;
    }

    input {
        border-color: red !important;
        color: red !important;
    }
}

.moneyTable {
    .el-input__icon {
        width: auto;
    }

    .el-input__inner {
        padding-right: 60px !important;
    }

    .el-slider__marks-text {
        width: 40px;
        text-align: center;
    }
}
</style>

<style lang="scss" scoped>
.setIcon {
    position: absolute;
    right: -5px;
    height: 20px;
    width: 20px;
    line-height: 20px;
    border: 1px solid #666;
}

.tooltipItem.on {
    border: 2px solid rgb(81, 203, 205);
}

.tooltipItem {
    height: 100%;
    align-items: center;
}

.assistrecordTable {
    border-color: #000;
    word-break: break-all;
    table-layout: fixed;

    thead tr,
    tbody tr,
    tfoot tr {
        display: table;
        width: 100%;
        table-layout: fixed;

        td {
            box-sizing: border-box;
        }
    }

    tbody {
        display: block;
    }

    .itemTitle {
        font-size: 14px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        font-size: 14px;
    }

}
</style>