<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" @click="showAddModel" :disabled="!checkedCompany.pcId || true">导出汇总表</el-button> -->
            </div>
        </div>
        <div class="oyauto">
            <table border="1" cellspacing="0" :style="tableStyle" class="assistrecordTable" v-loading="loading">
                <thead class=" baf7fafa">
                    <tr>
                        <td rowspan="2">
                            <div class="specialTable h100">
                                <div class="titleRight">研发项目编号</div>
                                <div class="titleCenter">累计发生额</div>
                                <div class="titleLeft">科目</div>
                                <div class="prohibit w500"></div>
                                <div class="prohibit2 w500"></div>
                            </div>
                        </td>
                        <td class="textc  baf7fafa itemTitle mw200 h30" v-for="(row, index) in tableData.title"
                            :key="index">
                            {{ row.code }}</td>
                        <td class="textc itemTitle mw100" rowspan="2">合计</td>
                    </tr>
                    <tr v-if="tableData.title && tableData.title.length > 0">
                        <td class="textc  baf7fafa itemTitle" v-for="(row, index) in tableData.title" :key="index">
                            {{ row.name }}</td>
                    </tr>
                    <tr v-if="tableData.title && tableData.title.length > 0">
                        <td class=" baf7fafa"></td>
                        <td class=" baf7fafa itemTitle textc h40" v-for="(row, index) in tableData.title" :key="index">
                            {{
                row.startDay + " 至 " + row.endDay }}</td>
                        <td class=" baf7fafa"></td>
                    </tr>
                </thead>
                <tbody v-if="tableData.list && tableData.list.length > 0" class="overflowHide"
                    :style="{ 'max-height': tableHeight + 'px' }">
                    <tr class="h40" v-for="(row, index) in tableData.list" :key="index"
                        :class="{ 'fwbold': boldTitle.indexOf(row.name) != -1 }">
                        <td class="textc itemContent">{{ row.name }}</td>
                        <td class="textc itemContent curPointer" v-for="(num, key) in row.data" :key="key"
                            @click="copyFun(num)">
                            {{ num | setMoney }}<span v-if="row.isPercentage">%</span>
                        </td>

                    </tr>
                </tbody>
                <tbody v-else>
                    <tr class="h200 textc">
                        <td colspan="3">暂无数据</td>
                    </tr>
                </tbody>
                <tfoot v-if="tableData.total">
                    <tr class="h40" v-for="(item, index) in tableData.total" :key="index">
                        <td class="textc itemContent">{{ item.name }}</td>
                        <td :colspan="tableData.title.length"></td>
                        <td class="textc itemContent">
                            {{ item.money | setMoney }}<span v-if="item.isPercentage">%</span>
                        </td>

                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script>
import { getProjectMoneyTotalList } from "@/api/projectMoney";
import { copyText } from "@/utils";
export default {
    name: "assistrecordTable2",
    props: {
        checkedCompany: {
            require: true,
            type: Object,
            default: () => {
                return {}
            }
        },
        yearValue: {
            require: true,
            type: String,
            default: "",
        }
    },
    data() {
        return {
            //列表配置
            loading: false, //表格加载
            tableData: {},
            //模态框配置
            tableHeight: 0,
            boldTitle: [
                '内部研究开发投入额',
                '人工费用',
                '直接投入',
                '折旧费用',
                '无形资产摊销',
                '其他费用',
                '新产品设计费',
                '委外技术开发费',
                '研究开发投入额（内、外部）小计',
            ],
            tableStyle:{},
        };
    },
    filters: {
        setMoney(money) {
            return (money ? money : 0).toFixed(2)
        }
    },
    watch: {
        checkedCompany: {
            handler(row) {
                //获取年份
                this.getList();
            },
        },
        yearValue: {
            handler(row) {
                //获取年份
                this.getList();
            },
            deep: true,
            immediate: true,
        }
    },
    mounted() {
        this.tableHeight = $(".content").height() - 75 - (25 + 56 + 56 + 74) - (30 + 60);
    },
    methods: {
        copyFun(text) {
            copyText(text)
            this.$message.info('复制成功！');
        },
        /**@method 搜索 */
        searchFun() {
            this.getList();
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                year: this.yearValue
            };
            if (!this.checkedCompany.pcId) {
                this.tableData = {};
                return;
            } else {
                params.pcId = this.checkedCompany.pcId;
            }
            try {
                this.loading = true;
                let result = await getProjectMoneyTotalList(params);
                this.loading = false;
                const { data } = result;
                let tableData = [];
                for (let row of data.list) {
                    let data = {
                        ...row,
                    }
                    tableData.push(data);
                }
                this.tableData.list = tableData;
                this.tableData.title = data.title;
                if (this.tableData.title) {
                    let tableW = 1000 + (this.tableData.title.length * 200);
                    if (tableW > $(".view").width()) {
                        this.tableStyle = {
                            width: tableW + 'px'
                        }
                    } else {
                        this.tableStyle = {
                            width: 'auto'
                        };
                    }

                } else {
                    this.tableStyle = {
                        width: 'auto'
                    };
                }
                this.tableData.total = data.total;
            } catch (error) {
               //-console.log(error);
                this.tableData = {};
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.assistrecordTable {

    thead,
    tbody tr,
    tfoot tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    tbody {
        display: block;
    }

    .itemTitle {
        font-size: 14px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        font-size: 14px;
        padding: 5px 10px;
    }

    .specialTable {
        position: relative;
        overflow: hidden;

        .titleLeft {
            position: absolute;
            left: 5px;
            bottom: 0px;
            font-size: 14px;
        }

        .titleRight {
            position: absolute;
            right: 5px;
            top: 0px;
            font-size: 14px;
        }

        .titleCenter {
            position: absolute;
            right: 5px;
            bottom: 0px;
            font-size: 14px;
        }

        .prohibit {
            transform-origin: left top;
            height: 0px;
            border-top: 1px solid #999;
            transform: rotate(13deg);
            -o-transform: rotate(13deg);
            -moz-transform: rotate(13deg);
            -webkit-transform: rotate(13deg);
            border-radius: 4px;
            position: absolute;
            top: 0;
            left: 0px;
        }

        .prohibit2 {
            position: absolute;
            top: 0;
            left: 0;
            height: 0px;
            border-top: 1px solid #999;
            transform: rotate(35deg);
            -o-transform: rotate(35deg);
            -moz-transform: rotate(35deg);
            -webkit-transform: rotate(35deg);
            border-radius: 4px;
            transform-origin: left top;
        }
    }
}
</style>