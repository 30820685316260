<template>
    <div class="view moneyTable">
        <el-tabs v-model="dataType" class="assistrecordTypeTab" @tab-click="getList()">
            <el-tab-pane :label="item.title" :name="item.id" v-for="(item, index) in typeObj"
                :key="index"></el-tab-pane>
        </el-tabs>
        <div class="pt40" v-if="showType == 'details'">
            <div class="oyauto" v-loading="loading">
                <table border="1" cellspacing="0" class="tableBorder assistrecordTable" :style="tableStyle">
                    <thead>
                        <tr class="h40 baf7fafa">
                            <td class="w150"></td>
                            <td class="w150"></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="textc itemTitle" v-for="(row, index) in tableData.title" :key="index">
                                {{ row.code }}</td>
                        </tr>
                        <tr class="h70 baf7fafa">
                            <td class="textc itemTitle w150">
                                月份
                            </td>
                            <td class="textc itemTitle w150">
                                人员
                            </td>
                            <td class="textc itemTitle">
                                工资
                            </td>
                            <td class="textc itemTitle">
                                凭证金额
                            </td>
                            <td class="textc itemTitle">
                                计入研发费用金额(元)
                            </td>
                            <td class="textc itemTitle" v-for="(row, index) in tableData.title" :key="index">
                                {{ row.name }}</td>
                        </tr>
                        <tr class="baf7fafa" v-if="tableData.list && tableData.list.length > 0">
                            <td class="w150"></td>
                            <td class="w150"></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="itemTitle textc h40" v-for="(row, index) in tableData.title" :key="index">{{
            row.startDay + " 至 " + row.endDay }}</td>
                        </tr>
                    </thead>
                    <tbody v-if="tableData.list && tableData.list.length > 0" class="overflowHide"
                        :style="{ 'max-height': tableHeight + 'px' }">
                        <tr class="h40" v-for="(row, index) in tableData.list" :key="index"
                            :class="{ 'fwbold': row.isTotal, 'baeee': row.isTotal, 'noFind': row.noFind }">
                            <td class="textc itemContent w150">{{ row.month }}月</td>
                            <td class="textc itemContent w150">{{ row.name }}</td>
                            <td class="textc itemContent curPointer" @click="hrefFun('/costEnterTable1')">{{ row.wages |
            setMoney }}</td>
                            <td class="textc itemContent curPointer" @click="hrefFun('/costEnterTable1')">{{
            row.voucherMoney | setMoney }}</td>
                            <td class="textc itemContent curPointer" @click="hrefFun('/costEnterTable1')">{{
            row.researchMoney | setMoney }}</td>
                            <td class="textc itemContent curPointer" v-for="(money, key) in row.data" :key="key">
                                <!-- <el-tooltip v-if="row.scaleData" class="tooltipItem" effect="dark"
                                :content="row.scaleData[key] + '%'" placement="top">
                                <div>{{ money | setMoney }}</div>
                            </el-tooltip>
                            <div v-else>{{ money | setMoney }}</div> -->
                                <el-popover placement="top" widht="150" trigger="click" v-if="!row.isTotal">
                                    <p v-if="row.scaleData">百分比：{{ row.scaleData[key].toFixed(2) + '%' }}</p>
                                    <div class="flex-c-c mt10">
                                        <el-button size="mini" type="danger" @click="delDataFun(row)"
                                            v-if="row.updateScale">重置金额</el-button>
                                        <el-button type="primary" size="mini" @click="showModelFun(row)">编辑</el-button>
                                    </div>
                                    <div slot="reference">
                                        <el-tooltip class="tooltipItem flex-c-c" effect="dark"
                                            :content="row.scaleData[key] + '%'" placement="top">
                                            <div>{{ money | setMoney }}</div>
                                        </el-tooltip>
                                    </div>
                                </el-popover>
                                <div v-else>{{ money | setMoney }}</div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr class="h200 textc curPointer" @click="hrefFun('/costEnterTable1')">
                            <td colspan="3">暂无数据</td>
                        </tr>
                    </tbody>
                    <tfoot v-if="tableData.total">
                        <tr class="h40 fwbold baf7fafa">
                            <td class="textc itemContent" colspan="4" width="300px">合 计</td>
                            <td class="textc itemContent">{{ tableData.total.wages | setMoney }}</td>
                            <td class="textc itemContent">{{ tableData.total.voucherMoney | setMoney }}</td>
                            <td class="textc itemContent">{{ tableData.total.researchMoney | setMoney }}</td>
                            <td class="textc itemContent" v-for="(money, key) in tableData.total.data"
                                :key="key + 'total'">
                                {{
            money | setMoney }}</td>
                        </tr>
                        <tr class="h40 fwbold baf7fafa">
                            <td class="textc itemContent" colspan="4" width="300px">未分配人数</td>
                            <td class="textc itemContent">{{ noFindCount }}</td>
                            <td class="textc itemContent">-</td>
                            <td class="textc itemContent">-</td>
                            <td class="textc itemContent" v-for="(money, key) in tableData.total.data"
                                :key="key + 'total'">
                                -</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        <div class="pt40" v-else>
            <moneyTable ref="moneyTable" :tableData="tableData" v-loading="loading" @delDataFun="delProjectMoney"
                @updateDataFun="updateData" @nullListHref="hrefFun('/costEnterTable1')" />
        </div>
        <el-dialog title="编辑项目预算" :close-on-click-modal="false" :modal-append-to-body="false" :visible.sync="showModel"
            width="80%" @close="closeDialog">
            <div>
                <div v-if="setData.row">
                    <div class="mb10">月份：{{ setData.row.month }}月</div>
                    <div class="mb10">人员：{{ setData.row.name }}</div>
                </div>
                <div class="line mt20 mb20"></div>
                <div>
                    <span class="mr20">项目预算比例</span>
                    <el-input-number :disabled="(projectIndex !== 0 && !projectIndex) || totalNumber === 0" size="mini"
                        v-model="n" @change="changeSlider" :min="0" :max="100"></el-input-number>
                </div>
                <div class="flex-c-c mb20 pl10 pr10">
                    <el-slider v-model="n" :disabled="(projectIndex !== 0 && !projectIndex) || totalNumber === 0"
                        :marks="marks" style="width:100%" @input="changeSlider"></el-slider>
                </div>
                <div class="flex-a-c flex-wrap-y">
                    <div class="ml10 mr10 fwbold"
                        :class="{ 'errorShow': (difference / totalNumber) > 1 || (difference / totalNumber) < 1 }">
                        <div class="mb10 mt10">总数 {{ totalNumber }}</div>
                        <div class="mb10 mt10 errTips">差值 {{ (difference - totalNumber) > 0 ? Math.abs(difference -
            totalNumber).toFixed(2) : 0 }}
                        </div>
                        <el-input v-model="difference" type="number" class="w200 errTips" readonly>
                            <div slot="suffix" class="el-input__icon">{{ setData.proportion }}%</div>
                        </el-input>
                    </div>
                    <div v-for="(row, index) in projectData" :key="index" class="ml10 mr10">
                        <div class="mb10 mt10">编号：{{ row.code }}</div>
                        <div class="mb10 mt10">
                            <el-checkbox v-model="row.checkedProject" :disabled="!row.isUpdate"
                                @change="checkedProjectChange($event, index)">{{ row.name }}</el-checkbox>
                        </div>
                        <el-input v-model="row.dataNumber" :disabled="!row.isUpdate" type="number" :max="totalNumber"
                            class="w200" @focus="focusInput(index)" @change="changeNumber">
                            <div slot="suffix" class="el-input__icon">{{ row.proportion }}%</div>
                        </el-input>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="submitData">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getProjectMoneyUserCount, delProjectMoneyScale, setProjectMoneyScale } from "@/api/projectMoney";
import moneyTable from "./moneyTable";
export default {
    name: "assistrecordTable10",
    components: { moneyTable },
    props: {
        checkedCompany: {
            require: true,
            type: Object,
            default: () => {
                return {}
            }
        },
        yearValue: {
            require: true,
            type: String,
            default: "",
        }
    },
    data() {
        return {
            //列表配置
            loading: false, //表格加载
            showModel: false,

            tableData: {},
            tableHeight: 0,
            //编辑
            n: 0,
            difference: 0,
            totalNumber: 0,
            setData: {},
            marks: {
                0: '0%',
                100: '100%'
            },
            projectIndex: null,
            projectData: [],
            tableStyle: {},
            //1：工资计算，2：社保，3：公积金，4：劳务费
            typeObj: [
                { id: "1-all", title: "工资" },
                { id: "1-details", title: "工资详情" },
                { id: "2-all", title: "社保" },
                { id: "2-details", title: "社保详情" },
                { id: "3-all", title: "公积金" },
                { id: "3-details", title: "公积金详情" },
                { id: "4-all", title: "劳务费" },
                { id: "4-details", title: "劳务费详情" },
            ],
            dataType: "1-all",
            showType: "all"
        };
    },
    computed:{
        noFindCount(){
            let count=0;
            for(let row of this.tableData.list){
                if(row.noFind){
                    count++;
                }
            }
            return count;
        }
    },
    filters: {
        setMoney(money) {
            return (money ? money : 0).toFixed(2)
        }
    },
    watch: {
        checkedCompany: {
            handler(row) {

                //获取年份
                this.getList();
            }
        },
        yearValue: {
            handler(row) {
                //获取年份
                this.getList();
            },
            deep: true,
            immediate: true,
        }
    },
    mounted() {
        this.tableHeight = $(".content").height() - 75 - (25 + 56 + 56 + 74) - (40 + 70 + 40);
    },
    methods: {
        hrefFun(url) {
            if (this.yearValue == 'all') {
                this.$router.push(url);
            } else {
                this.$router.push(url + "?year=" + this.yearValue);
            }
        },
        /**@method 修改项目金额 */
        updateData(projectData) {
            let updateList = [];
            for (let row of projectData.data) {
                let query = {
                    dataType: projectData.row.dataType,
                    fileType: projectData.row.fileType,
                    month: projectData.row.month,
                    pcId: this.checkedCompany.pcId,
                    money: row.dataNumber,
                    year: this.yearValue,
                    pdId: row.pdId,
                };
                if (row.isYearEnd) {
                    query.isYearEnd = row.isYearEnd;
                }
                if (row.puId) {
                    query.puId = row.puId;
                }
                updateList.push(query);
            }
            let params = {
                updateList: updateList
            };
            setProjectMoneyScale(params).then(res => {
                if (res.code === 200) {
                    this.$message.success("编辑完成")
                    this.closeDialog();
                    this.getList();
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message);
                }
            })
        },
        /**@method 重置项目金额 */
        delProjectMoney(row) {
            let params = {
                dataType: row.dataType,
                fileType: row.fileType,
                month: row.month,
                pcId: this.checkedCompany.pcId,
                year: this.yearValue,
            }
            if (row.isYearEnd) {
                params.isYearEnd = row.isYearEnd;
            }
            if (row.puId) {
                params.puId = row.puId;
            }
            delProjectMoneyScale(params).then(res => {
                if (res.code === 200) {
                    this.$message.success("重置预算完成");
                    this.getList();
                }
            }).catch(err => {

                if (err.code === 201) {
                    this.$message.error(err.message);
                }
            })
        },
        /**@method 提交修改值 */
        submitData() {
            if (Number(this.difference) > Number(this.totalNumber)) {
                this.$message.warning("项目预算比例总和不能超过100%");
            } else if (Number(this.difference) < Number(this.totalNumber)) {
                this.$message.warning("项目预算比例总和必须100%");
            } else {
                this.updateData({ row: this.setData.row, data: this.projectData })
            }
        },
        /**@method  选中编辑金额*/
        checkedProjectChange(isChecked, index) {
            let difference = 0;
            if (isChecked) {
                let updateData = [];
                let dataNumber = this.totalNumber * (this.projectData[index].oldProportion / 100);
                this.projectData[index].dataNumber = (dataNumber).toFixed(2);
                this.projectData[index].proportion = (this.projectData[index].oldProportion).toFixed(2);
                for (let row of this.projectData) {
                    if (row.checkedProject && row.isUpdate) {
                        updateData.push(row);
                        difference += Number(row.dataNumber);
                    }
                }
            } else {
                let projectDataLength = 0;//分摊数量
                this.projectData[index].oldProportion = Number(this.projectData[index].proportion);
                for (let row of this.projectData) {
                    if (row.checkedProject && row.isUpdate) {
                        projectDataLength++;
                    }
                }
                let averageValue = this.projectData[index].dataNumber / projectDataLength;//平均每份的值
                averageValue = Number(averageValue.toFixed(2));
                let isUpdateIndex = 0;
                for (let i in this.projectData) {
                    let row = this.projectData[i];
                    if (row.checkedProject && row.isUpdate) {
                        isUpdateIndex = i;
                        row.dataNumber = (Number(row.dataNumber) + averageValue).toFixed(2);
                        row.proportion = ((this.totalNumber && row.dataNumber ? Number(row.dataNumber) / this.totalNumber : 0) * 100).toFixed(4);
                        difference += Number(row.dataNumber);
                    }
                }
                let errNumber = this.totalNumber - difference;//误差
                difference += errNumber;
                this.projectData[isUpdateIndex].dataNumber = (Number(this.projectData[isUpdateIndex].dataNumber) + errNumber).toFixed(2);
                this.projectData[isUpdateIndex].proportion = ((this.totalNumber && this.projectData[isUpdateIndex].dataNumber ? Number(this.projectData[isUpdateIndex].dataNumber) / this.totalNumber : 0) * 100).toFixed(4);
                this.projectData[index].dataNumber = 0;
                this.projectData[index].proportion = 0;


            }
            this.difference = Number(difference.toFixed(2));
            this.setData.proportion = ((this.difference && this.totalNumber ? this.difference / this.totalNumber : 0) * 100).toFixed(2);
        },
        /**@method 删除条 */
        delDataFun(row) {
            this.delProjectMoney(row);
        },
        closeDialog() {
            this.showModel = false;
            this.n = 0;
            this.projectIndex = null;
        },
        /** */
        changeNumber(val) {
            this.n = Number(((Number(val) / this.totalNumber) * 100).toFixed(2));
        },
        /**@method 获取光标操作 */
        focusInput(i) {
            this.projectIndex = i;
            this.n = Number(this.projectData[i].proportion);
            // this.projectData[i].proportion = ((this.projectData[i].dataNumber / this.totalNumber) * 100).toFixed(2)
        },
        /**@method 调节滚动条 */
        changeSlider(val) {
            if (this.projectIndex || this.projectIndex === 0) {
                let difference = 0;
                let dataNumber = (this.totalNumber * (val / 100)).toFixed(2);
                let residueNumber = this.totalNumber - dataNumber;
                this.$set(this.projectData[this.projectIndex], "dataNumber", dataNumber);
                if (residueNumber >= 0) {
                    for (let row of this.projectData) {
                        difference += Number(row.dataNumber);
                        row.proportion = ((row.dataNumber && this.totalNumber ? row.dataNumber / this.totalNumber : 0) * 100).toFixed(2)
                    }
                }
                this.difference = Number(difference.toFixed(2))
                this.setData.proportion = ((this.difference && this.totalNumber ? this.difference / this.totalNumber : 0) * 100).toFixed(2);
            }
        },
        /**@method 显示模态框 */
        showModelFun(data) {
            let moneys = 0;
            let researchMoney = data.researchMoney ? data.researchMoney : 0;
            let project = [];
            this.difference = researchMoney;
            this.totalNumber = researchMoney;
            for (let index in data.data) {
                let row = this.tableData.title[index];
                moneys += row.money;
                row.isUpdate = data.haveData[index];
                row.dataNumber = data.data[index] ? data.data[index] : 0;
                row.checkedProject = data.haveData[index];

                row.proportion = ((data.data[index] && researchMoney ? data.data[index] / researchMoney : 0) * 100).toFixed(2);
                row.oldProportion = Number((data.data[index] && researchMoney ? data.data[index] / researchMoney : 0) * 100);
                let tiem = {
                    ...row,
                    ...data
                }
                project.push(tiem);
            }

            this.setData = {
                row: data,
                moneys: moneys ? moneys : 0,
                proportion: ((this.difference && researchMoney ? this.difference / researchMoney : 0) * 100).toFixed(2)
            };
            this.projectData = JSON.parse(JSON.stringify(project));
            this.showModel = true;
        },
        /**@method 获取列表 */
        async getList() {
            /**
            * 0：人工费用{1：工资计算，2：社保，3：公积金，4：劳务费}，
            * 1：直接投入{1：材料，2：燃料，3：动力，4：制造费，5：样品，6：试验费，7：设备费，8：租赁}，
            * 2：折旧费用{1：研发设备，2：研发仪器}，
            * 3：无形资产摊销{1：软件，2：专利，3：非专利}，
            * 4：新产品设计费{1：新产品，2：新工艺，3：新药临床，4：勘探开发技术的现场}，
            * 5：其他费用{1：差旅，2：办公用品，3：会议费，4：知识产权，5：技术服务费，6：医疗附加}，
            * 6：委外技术开发费{1：委外开发，2：境外开发}）
             */
            let params = {
                year: this.yearValue,
                fileType: 0,
            };
            let dataTypes = this.dataType.split("-");
            let dataType = dataTypes[0];
            params.dataType = dataType;
            if (dataTypes[1] == 'details') {
                params.totalByMonth = true;//是否按月份合计
                params.showNoFind = true;
            } else {
                params.totalByDataType = true;//根据类型整合
            }

            if (!this.checkedCompany.pcId) {
                this.tableData = {};
                return;
            } else {
                params.pcId = this.checkedCompany.pcId;
            }
            this.tableData = {};
            try {
                this.loading = true;
                let result = await getProjectMoneyUserCount(params);
                this.showType = dataTypes[1];
                this.loading = false;
                const { data } = result;
                let tableData = [];
                for (let row of data.list) {
                    let data = {
                        ...row,
                    }
                    tableData.push(data);
                }
                let list = {};
                list.list = tableData;
                list.title = data.title;
                list.total = data.total[0];
                this.tableData = list;
                if (this.tableData.title) {
                    let tableW = 1000 + (this.tableData.title.length * 200);
                    if (tableW > $(".view").width()) {
                        this.tableStyle = {
                            width: tableW + 'px'
                        }
                    } else {
                        this.tableStyle = {
                            width: 'auto'
                        };
                    }

                } else {
                    this.tableStyle = {
                        width: 'auto'
                    };
                }
               //-console.log(this.tableData);
            } catch (error) {
               //-console.log(error);
                this.loading = false;
                this.tableData = {};
            }
        },
    },
};
</script>

<style lang="scss">
.errorInput {
    input {
        border-color: red !important;
        color: red !important;
    }
}

.moneyTable {
    .el-input__icon {
        width: auto;
    }

    .el-input__inner {
        padding-right: 60px !important;
    }

    .el-slider__marks-text {
        width: 40px;
        text-align: center;
    }
}
</style>

<style lang="scss" scoped>
.noFind,
.noFind:hover {
    background-color: #ffb4aa !important;
    color: #fff;
}

.assistrecordTable {
    border-color: #000;
    word-break: break-all;
    table-layout: fixed;

    thead tr,
    tbody tr,
    tfoot tr {
        display: table;
        width: 100%;
        table-layout: fixed;

        td {
            box-sizing: border-box;
        }
    }

    tbody {
        display: block;
    }

    .itemTitle {
        font-size: 14px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        font-size: 14px;
        padding: 5px 10px;
    }

}
</style>